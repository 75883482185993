<template>
    <multiselect v-model="value" 
        tag-placeholder="Add this as new tag"
        placeholder="Search or add a tag"
        label="name"
        track-by="code"
        :options="options"
        :multiple="true"
        :taggable="true"
        @input="onChange"
        @tag="addTag">
    </multiselect>
</template>
<script>
    import Multiselect from 'vue-multiselect'
    import api from '/src/utils/api';

    const TagSelector = {
        components: { Multiselect },
        data() {
            return {
                selectedTags: null,
                value: [],
                options: []
            }
        },
        methods: {
            addTag (newTag) {
                // Convert tags to lower case and remove characters which are not alphanumeric or spaces.
                newTag = newTag.trim().toLowerCase().replace(/[^a-z0-9 ]/ig, '');

                const tag = {
                    name: newTag,
                    code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
                }
                this.options.push(tag)
                this.value.push(tag)
            },
            onChange (value) {
                this.updateSelectedTags(value);
            },
            async updateListTags() {
                api.updateTags(this.listId, this.selectedTags);
            },
            updateSelectedTags(newSelectedTags) {
                this.selectedTags = newSelectedTags;
            },
        },
        async mounted() {
            this.selectedTags = await api.getTags(this.listId);
            this.options = await api.getTags(null); // Get all system tags and those created by user.
            this.value = this.selectedTags;
        },
        props: ['listId'],
    }
    export default TagSelector;
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
